import { logger } from '@/services/logger/loggers'

const loadScope = async (url: string, scope: any): Promise<unknown> => {
  const element = document.createElement('script')
  const promise = new Promise((resolve, reject) => {
    element.src = url
    element.type = 'text/javascript'
    element.async = true
    element.onload = () => resolve(window[scope])
    element.onerror = reject
  })
  document.head.appendChild(element)
  promise.finally(() => document.head.removeChild(element))
  return promise
}

export const loadModule = async (
  url: string,
  namespace: string,
  module: string
): Promise<{ mount: any }> => {
  try {
    const container = await loadScope(url, namespace)
    // @ts-ignore
    // eslint-disable-next-line no-undef
    await __webpack_init_sharing__('default')
    // @ts-ignore
    // eslint-disable-next-line no-undef
    await container.init(__webpack_share_scopes__.default)
    // @ts-ignore
    const factory = await container.get(module, namespace)
    return factory()
  } catch (error) {
    logger.error('[ts-website-project-devtool] Error loading module', { error })
    throw error
  }
}
