import { convertToKebabCase } from '@/modules/utils'
import {
  AnalyticsMessageContext,
  IAnalyticsContext,
  IAnalyticsProvider,
} from '@sennder/senn-node-microfrontend-interfaces'
import { logger } from '@/services/logger/loggers'
import { executeSafelyWithLogging } from './errors-handler'
import { amplitude } from '@sennder/senn-node-analytics-frontend'

let lastPageEvent: string | null = null

const DEFAULT_MODULE = 'ts-website-project-devtool'
const DEFAULT_SUBMODULE = ''
const DEFAULT_EVENT_SOURCE = 'ts-website-project-devtool'

export const defaultAnalyticsContext: IAnalyticsContext = {
  module: DEFAULT_MODULE,
  submodule: DEFAULT_SUBMODULE,
}

const getPageViewEventName = (name: string): string => {
  if (!name) {
    throw Error('[ts-website-project-devtool - Analytics]: Could not track invalid page name')
  }
  return `ts-website-project-devtool/view/${convertToKebabCase(name)}-page`
}

export class AnalyticsProvider implements IAnalyticsProvider {
  private _context: AnalyticsMessageContext

  constructor(context: AnalyticsMessageContext) {
    this._context = context
    this.trackEvent = this.trackEvent.bind(this)
    this.trackPage = this.trackPage.bind(this)
    this.setContext = this.setContext.bind(this)
  }

  trackEvent(name: string, properties: Record<string, any> = {}) {
    if (!name) {
      logger.warn(
        '[ts-website-project-devtool - Analytics]: Could not track, invalid event name',
        { name, properties }
      )
      return
    }
    executeSafelyWithLogging(() => {
      const eventName = name
      if (eventName.endsWith('-page')) {
        if (eventName !== lastPageEvent) {
          lastPageEvent = eventName
        } else {
          return
        }
      }
      amplitude.trackEvent({
        name: eventName,
        properties: {
          ...properties,
          submodule:
            properties.submodule ||
            this._context.submodule ||
            DEFAULT_SUBMODULE,
          module: properties.module || this._context.module || DEFAULT_MODULE,
          source: DEFAULT_EVENT_SOURCE,
        },
      })
    }, 'trackEvent')
  }

  trackPage(page: string, properties: Record<string, any> = {}) {
    executeSafelyWithLogging(() => {
      const pageEventName = getPageViewEventName(page)
      if (pageEventName !== lastPageEvent) {
        amplitude.trackEvent({
          name: pageEventName,
          properties: {
            ...properties,
            submodule:
              properties.submodule ||
              this._context.submodule ||
              DEFAULT_SUBMODULE,
            module: properties.module || this._context.module || DEFAULT_MODULE,
            source: DEFAULT_EVENT_SOURCE,
          },
        })
      }
      lastPageEvent = pageEventName
    }, 'trackPage')
  }

  /**
   * Defines context for all events
   * @deprecated This method is deprecated. Please provide the context in the IModuleConfig interface or override the context in the trackEvent method
   * @param context AnalyticsMessageContext
   */
  setContext(context: AnalyticsMessageContext) {
    console.warn(
      'setContext is deprecated. Please provide the context in the IModuleConfig interface or override the context in the trackEvent method',
      context
    )
  }
}

export const analytics = new AnalyticsProvider(defaultAnalyticsContext)
