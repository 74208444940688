import {
  identifyUserInAnalytics,
  initAnalytics,
  resetAnalyticsSession,
  stopAnalytics,
} from './analytics'
import { logger } from './logger/loggers'
import { initLogger, stopLogger } from './logger'
import { initMonitor, stopMonitor } from './monitor'
import { initSentry, stopSentry } from './sentry'

let state: 'stopped' | 'started' = 'stopped'

export function startTracking() {
  if (state === 'started') {
    return
  }
  initLogger()
  initMonitor()
  initSentry()
  initAnalytics()
  state = 'started'

  log('tracking is started')
}

export function identifyUserInTracking(onLogin = false) {
  if (state === 'stopped') {
    return
  }
  identifyUserInAnalytics(onLogin)

  log('user is identified')
}

export function anonymizeTracking() {
  if (state === 'stopped') {
    return
  }
  resetAnalyticsSession()

  log('user is anonymized')
}

export function stopTracking() {
  stopAnalytics()
  stopSentry()
  stopMonitor()
  stopLogger()
  state = 'stopped'

  log('tracking is stopped')
}

function log(message: string) {
  const prefix = '[ts-website-project-devtool - tracking]'
  logger.info(`${prefix} ${message}`, {})
  console.log(`${prefix} ${message}`)
}
