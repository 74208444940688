import {
  VNode,
  WatchStopHandle,
  defineComponent,
  h,
  onMounted,
  onUnmounted,
  watch,
  ref,
} from 'vue'
import { useRoute, useRouter } from 'vue-router'

import {
  IMicrofrontendData,
  IModulesConfig,
  MountFunc,
} from '@sennder/senn-node-microfrontend-interfaces'

import { createRandomString, cloneReactiveToRaw } from './utils'
import { modules } from './'
import {
  getStateData,
  getStateProviders,
  getStateCallbacks,
  StoreData,
} from '@/store'

import router from '@/router'
import { MicrofrontendLogger, logger } from '@/services/logger/loggers'
import { AnalyticsProvider } from '@/services/analyticsProvider'
import { getFeatureFlagForRoute } from './visibility-handlers'

type ComponentDefinition = Parameters<
  typeof defineComponent<Record<string, any>>
>[0] & {
  setup(): () => VNode
}

const msg = (m: string) => `[µ-frontend]: ${m}`

export const generateComponent = async (
  moduleConfig: IModulesConfig
): Promise<ComponentDefinition> => {
  return {
    setup() {
      const $router = useRouter()
      const $route = useRoute()
      const syncParentRouter = async (route: any) => {
        const currentPath = $route.fullPath
        if (
          (typeof route === 'string' && currentPath === route) ||
          (typeof route === 'object' && currentPath === route.path)
        ) {
          return
        }
        await $router.push(route)
      }

      const mountId = createRandomString()
      const containerId = `${mountId}-${moduleConfig.component}`
      const isLoaded = ref<boolean | undefined>()
      const unmount = ref<(() => void) | undefined>()
      const containerRef = ref()
      let watchHandles: WatchStopHandle[] = []

      onMounted(async () => {
        // watch route feature flag changes
        watchHandles.push(
          watch(
            () => getStateData().featureFlags,
            (featureFlags) => {
              if (!$route) {
                return
              }
              const routeFeatureFlag = getFeatureFlagForRoute($route)
              if (routeFeatureFlag && !featureFlags[routeFeatureFlag]) {
                router.push({ path: '/' })
              }
            },
            // immediately execute to check feature flag on direct page load
            { immediate: true }
          )
        )

        // mount µ-frontend
        try {
          // download mount function
          // TODO: You should replace any with specific interface data you are working
          const mountFunction: MountFunc<any> = await modules[
            moduleConfig.component
          ].connector.bootstrap()

          /**
           * If containerRef is not available, this means that wrapper Vue component has already unmounted before microfrontend was loaded.
           * E.g. this might happen during navigation to another page before microfrontend was loaded.
           */
          if (!containerRef.value) {
            console.debug(
              msg(`🚫 will not mount ${moduleConfig.component} (${mountId})`)
            )
            return
          }

          // prepare µ-frontend data
          const data = getStateData()

          const analyticsProvider = new AnalyticsProvider(
            moduleConfig.analyticsContext
          )

          const microfrontendData: IMicrofrontendData<StoreData> = {
            data: cloneReactiveToRaw(data),
            callbacks: {
              ...getStateCallbacks(),
              onUnauthorized: () => {
                // TODO: replace with actual logic
                console.log('Should logout the app')
              },
              syncParentRouter,
            },
            providers: {
              ...getStateProviders(),
              logger: new MicrofrontendLogger(moduleConfig.logContext),
              analytics: analyticsProvider,
              segment: analyticsProvider,
            },
          }

          // execute mount function
          const mountResult = mountFunction(
            `#${containerId}`,
            microfrontendData
          )

          if (mountResult.unmount) {
            unmount.value = mountResult.unmount
          }

          if (mountResult.syncChildRouter) {
            watchHandles.push(
              watch(
                $route,
                (to) => {
                  mountResult.syncChildRouter(to?.fullPath)
                },
                {
                  immediate: true,
                }
              )
            )
          }

          if (mountResult.onSharedDataChanged) {
            watchHandles.push(
              watch(
                () => data,
                (data) => {
                  if (mountResult.onSharedDataChanged) {
                    mountResult.onSharedDataChanged(cloneReactiveToRaw(data))
                  }
                },
                { deep: true }
              )
            )
          }

          console.debug(
            msg(`📥 mounted ${moduleConfig.component} (${mountId})`)
          )

          isLoaded.value = true
        } catch (error) {
          logger.error(
            `[µ-frontend ${moduleConfig.component} bootstrap error]`,
            {
              error,
            }
          )
          isLoaded.value = false
        }
      })

      onUnmounted(() => {
        if (unmount.value) {
          unmount.value()
        }
        if (watchHandles.length) {
          for (const unwatch of watchHandles) {
            unwatch()
          }
          watchHandles = []
        }
        console.debug(
          msg(`🗑️ unmounted ${moduleConfig.component} (${mountId})`)
        )
      })

      return () =>
        h('div', [
          isLoaded.value === false
            ? h(
                'dsf-banner',
                {
                  'data-test': 'mf-not-loaded',
                  dsf_level: 'urgent',
                  class: 'max-w-max',
                },
                `${moduleConfig.component} - is not available`
              )
            : h('div', { id: containerId, ref: containerRef }),
        ])
    },
  }
}
