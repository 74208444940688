import {
  LogHandlerType,
  ILogContext,
  ILogProvider,
  IShellLogProvider,
  LogMessageContext,
  IDataDogContext,
} from '@sennder/senn-node-microfrontend-interfaces'
import { loggerInstance } from '.'

const DEFAULT_MODULE = 'ts-website-project-devtool'
const DEFAULT_CODE_OWNERS = 'frontend-systems'

export const defaultLogContext = {
  module: DEFAULT_MODULE,
  codeOwners: DEFAULT_CODE_OWNERS,
}

function log(
  type: 'debug' | 'info' | 'warn' | 'error',
  loggerContext: ILogContext,
  message: string,
  messageContext: LogMessageContext,
  customProperties: string[] = [],
  error: Error | undefined = undefined
) {
  const overrideMessageContext = {
    ...messageContext,
    module: messageContext.module || loggerContext.module || DEFAULT_MODULE,
    codeOwners:
      messageContext.codeOwners ||
      loggerContext.codeOwners ||
      DEFAULT_CODE_OWNERS,
  }
  loggerInstance[type](message, overrideMessageContext, customProperties, error)
}

class BaseLogger implements ILogProvider {
  protected _context: ILogContext

  constructor(context: ILogContext) {
    this._context = context
    this.setContext = this.setContext.bind(this)
    this.setUserData = this.setUserData.bind(this)
    this.unsetUserData = this.unsetUserData.bind(this)
    this.debug = this.debug.bind(this)
    this.info = this.info.bind(this)
    this.warn = this.warn.bind(this)
    this.error = this.error.bind(this)
  }

  /**
   * Defines context for all log messages
   * @deprecated This method is deprecated. Please provide the context in the IModuleConfig interface or override the context in the log method
   * @param context IDataDogContext
   */
  setContext(context: IDataDogContext) {
    console.warn(
      'setContext is deprecated. Please provide the context in the IModuleConfig interface (modules-configuration.ts) or override the context in the log method',
      context
    )
  }

  /**
   * Defines user data for all log messages
   * @deprecated This method is deprecated. Shell will provide user data automatically
   * @param userId user ID
   * @param userEmail user email
   */
  setUserData(userId: string, userEmail: string) {
    console.warn(
      'setUserData is deprecated. Shell will provide user data automatically'
    )
  }

  /**
   * Removes user data from all log messages
   * @deprecated This method is deprecated. Shell will unset user data automatically
   */
  unsetUserData() {
    console.warn(
      'unsetUserData is deprecated. Shell will unset user data automatically'
    )
  }

  debug(
    message: string,
    messageContext: LogMessageContext,
    customProperties?: string[]
  ) {
    log('debug', this._context, message, messageContext, customProperties)
  }

  info(
    message: string,
    messageContext: LogMessageContext,
    customProperties?: string[]
  ) {
    log('info', this._context, message, messageContext, customProperties)
  }

  warn(
    message: string,
    messageContext: LogMessageContext,
    customProperties?: string[]
  ) {
    log('warn', this._context, message, messageContext, customProperties)
  }

  error(
    message: string,
    messageContext: LogMessageContext,
    customProperties?: string[],
    error?: Error
  ) {
    log(
      'error',
      this._context,
      message,
      messageContext,
      customProperties,
      error
    )
  }
}

class ShellLogger extends BaseLogger implements IShellLogProvider {
  constructor(context: ILogContext) {
    super(context)
    this.setUserData = this.setUserData.bind(this)
    this.unsetUserData = this.unsetUserData.bind(this)
    this.setHandlerType = this.setHandlerType.bind(this)
  }

  setUserData(userId: string, userEmail: string): void {
    loggerInstance.setUserData(userId, userEmail)
  }

  unsetUserData(): void {
    loggerInstance.unsetUserData()
  }

  setHandlerType(handlerType: LogHandlerType) {
    loggerInstance.setHandlerType(handlerType)
  }
}

export const logger = new ShellLogger(defaultLogContext)

export { BaseLogger as MicrofrontendLogger }