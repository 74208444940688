import { IMonitoringProvider } from '@sennder/senn-node-microfrontend-interfaces'
import {
  APP_VERSION,
  ENV,
  DATADOG_APP_ID,
  DATADOG_CLIENT_TOKEN,
} from '@/common/config'
import DatadogMonitor from '@sennder/senn-node-frontend-loggers/lib/datadog/DatadogMonitor'
import { logger } from './logger/loggers'

const SITE = 'datadoghq.eu'
const SERVICE = 'ts-website-project-devtool'

const consoleMonitor: IMonitoringProvider = {
  sendError(error, context) {
    console.error(error, context)
  },
}

export let monitor = consoleMonitor

let datadogMonitor: DatadogMonitor | undefined = undefined

function getDatadogMonitor() {
  if (!datadogMonitor) {
    if (!DATADOG_CLIENT_TOKEN) {
      throw new Error('DATADOG_CLIENT_TOKEN not found')
    }
    if (!DATADOG_APP_ID) {
      throw new Error('DATADOG_APP_ID not found')
    }
    datadogMonitor = new DatadogMonitor(
      DATADOG_APP_ID,
      DATADOG_CLIENT_TOKEN,
      SERVICE,
      SITE,
      ENV,
      APP_VERSION
    )
  }
  return datadogMonitor
}

export function initMonitor() {
  logger.debug('[ts-website-project-devtool - monitor] Starting Datadog monitor', {})
  monitor = getDatadogMonitor()
}

export function stopMonitor() {
  logger.warn(
    '[ts-website-project-devtool - monitor] Stopping Datadog monitor is not supported',
    {}
  )
}