export const ENV = process.env.VUE_APP_ENV as 'dev' | 'prod' | 'uat'
export const APP_VERSION = process.env.VUE_APP_VERSION
export const LAUNCH_DARKLY_KEY = process.env.VUE_APP_LAUNCH_DARKLY_KEY
export const LAUNCH_DARKLY_STATIC_KEY = process.env.VUE_APP_LAUNCH_DARKLY_STATIC_KEY
export const DATADOG_APP_ID = process.env.VUE_APP_DATADOG_APP_ID
export const DATADOG_CLIENT_TOKEN = process.env.VUE_APP_DATADOG_CLIENT_TOKEN
export const AMPLITUDE_API_KEY = process.env.VUE_APP_AMPLITUDE_API_KEY
export const LOCAL_MICROFRONTENDS = process.env.VUE_APP_LOCAL_MICROFRONTENDS
export const AUTH0_USE_TOKEN_POPUP = ENV !== 'prod'

export const isLocalEnv = (): boolean => {
  return ENV === 'dev'
}

export function isUatEnv() {
  return ENV === 'uat'
}

export const SENTRY_DSN = process.env.VUE_APP_SENTRY_DSN
